@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.modalText {
  width: 100%;
  height: auto;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: block;
  align-items: center;
  text-align: center;
  color: rgb(0, 0, 0, 0.5);
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 25px;
}

.buttonDiv {
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 20px;

  .modalButton {
    background-color: #f19e38;
    color: #ffffff;
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-family: Quicksand;
    font-style: normal;
    border-radius: 6px;
    height: 40px;
    width: 100%;
    text-align: center;
  }
}
